import { MatDialog } from '@angular/material/dialog';
import { AppComponent } from './../app.component';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth/authentication-service';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    /**
     *
     */
    constructor(private dialogRef: MatDialog, private router: Router) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Intercept the request or response here
        return next.handle(request)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    try {
                        if (err.status === 503) {
                            console.log('Service Temporarily Unavailable', '');
                        }
                        else if (err.status === 500) {
                            // tslint:disable-next-line: max-line-length
                            console.log('There is some temporary error on the server. Please try again or let us know if the error persist.', 'X');
                        } else if (err.status === 404) {
                            // tslint:disable-next-line: max-line-length
                            console.log('The resource you are looking for is not available on the server. Please try again or let us know if the error persist.', 'X');
                        } else if (err.status === 400) {
                            let msg = 'Bad Request';
                            if (err.error && err.error.message) {
                                msg = err.error.message;
                            }
                            console.log(msg);
                        } else if (err.status === 502) {
                            console.log('Bad Gateway.');
                        } else if (err.status === 401 || err.status === 403) {
                            this.dialogRef.closeAll();
                            localStorage.removeItem('mean-token');
                            this.router.navigate(['login']);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                    // Handle the error here
                    // You can log the error, display a notification, or perform any other actions
                    return throwError(err);
                })
            );
    }
}
