import {Component, OnInit, ViewChild} from '@angular/core';
import {TokenPayload, UserService} from "../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {RoutingstateService} from "../services/routingstate.service";
import {ExchangeService} from "../services/exchange.service";
 import { NotificationService } from '../notification/notification.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../services/storage.service';
import { ReCaptcha2Component } from 'ngx-captcha';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  theme:any;
  size:any;
  lang:any;
  type:any;

  hide:boolean = true;
  submit:boolean = false ;
  userLogin: TokenPayload = {
    email: '',
    password: '',
    authflag: true,
    _csrf: null,
    tfa_status:0,
    tfa:0
};
@ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
public siteKey:any = '';
  tfaobj  = {
    tfa_status:0,
    tfa:'',
    _csrf:null,
    email:'',
    password: '',
    authflag: true,
  }
  dataobj = {
    status:'',
    ip_addr:'',
    _csrf:'',
  }
  arriveresponse=false;
previousRoute: string;
verificationstr = '';
curlanguage = 'pr';

constructor(private userService: UserService,
            private router: Router,
            private routingState: RoutingstateService,
            private actroute: ActivatedRoute,
            private exchageService: ExchangeService,
            private notifyService : NotificationService,
            private location: Location,
            public translate: TranslateService,
            private storageService : StorageService
          ) {
            this.siteKey = "6LfmI0MlAAAAAKQhPIF7bW1AKWgaZ_FO2L5iQj-J"
}
showPassword = false;

toggleShowPassword() {
  this.showPassword = !this.showPassword;
}
ngOnInit() {
    if (this.userService.isLoggedIn()) {
        window.location.href="/kyc";
    } else {
        this.verificationstr = this.actroute.snapshot.paramMap.get('id');
        if (this.verificationstr) {
            this.verifyemail(this.verificationstr);
        }
        this.csrf();
        this.previousRoute = this.routingState.getPreviousUrl();
        if (this.userService.isLoggedIn()) {
            this.router.navigateByUrl(this.previousRoute);
        }
    }
}
verifyemail(str) {
    this.exchageService.getEmailVerified(str).subscribe((result) => {
        if (this.storageService.getLanguage()=='es') {
          this.notifyService.showSuccess("", result.messagees)
        }else{
          this.notifyService.showSuccess("", result.message)
        }
        this.router.navigateByUrl('/login');
    }, (err) => {
        //this.notifyService.showError('',err.error.message);
        if (this.storageService.getLanguage()=='es') {
          this.notifyService.showSuccess("", err.error.messagees)
        }else{
          this.notifyService.showSuccess("", err.error.message)
        }
    })
}
handleSuccess(captchaResponse: any)  {
  this.userLogin['captcha'] = captchaResponse;
}
login() {
    this.submit = true;
    if(JSON.stringify(this.userLogin).search('""') !=-1){
      return false
    } else {
      this.csrf();
      if(!this.userLogin['captcha'])  {
        if(this.storageService.getLanguage() == 'es'){
          this.notifyService.showError('', 'Captcha on nõutav');
        } else {
          this.notifyService.showError('', 'Captcha is Required') 
        }
        return false
      }
      
      this.userService.login(this.userLogin,this.storageService.getLanguage()).subscribe((response) => {
        if(response.tfa_status == 1){
          this.tfaobj.tfa_status = 1
        }
        if (response.token) {          
          if (this.storageService.getLanguage()=='es') {
            this.notifyService.showSuccess("", response.messagees)
          }else{
            this.notifyService.showSuccess("", response.message)
          }
            setTimeout(() => {
            //this.router.navigateByUrl('/kyc');
            window.location.href="/kyc";
            }, 1000)
            this.captchaElem.resetCaptcha();
           
        }else{
          if (this.storageService.getLanguage()=='es') {
            this.notifyService.showSuccess("", response.messagees)
          }else{
            this.notifyService.showSuccess("", response.message)
          }
        }
      }, (loginerr) => {
          this.arriveresponse = true;
            if(loginerr.error.ipblock==true){
              this.userService.getIpvfourClient().subscribe((ipres)=>{
                if(ipres){
                  this.userService.csrf().subscribe((response) => {
                    this.dataobj._csrf = response._csrf;
                    this.dataobj.ip_addr = ipres.ip;
                    this.dataobj.status = '0';
                    this.dataobj['name'] = loginerr.error.name;
                    this.userService.addIpAddrclient(this.dataobj).subscribe(data=>{
                      if(data){
                        this.dataobj.status = data.status;
                        this.arriveresponse = false;
                        window.location.href="/block";
                      }
                    },(error)=>{             
                      console.log(error);
                    })
                  },(csrferr)=>{
                    console.log(csrferr);
                  })      
              }
              },(err)=>{
                console.log(err);
              })
            } else {
              this.arriveresponse = false;
              if (this.storageService.getLanguage()=='es') {
                this.notifyService.showError("", loginerr.error.messagees)
              }else{
                this.notifyService.showError("", loginerr.error.message)
              }
            }

      });
    }
}

csrf() {
    this.userService.csrf().subscribe((response) => {
        this.userLogin._csrf = response._csrf;
        this.tfaobj._csrf = response._csrf;
    }, (err) => {
    });
}
redirectforget(){
    this.router.navigate(['forgot-password']);
}
redirectFunc(a){
    this.router.navigate([a]);
}
back() {
    this.location.back();
}
GrantAccess(){
  this.tfaobj.email=this.userLogin.email;
  this.tfaobj.password = this.userLogin.password;
    this.userService.TFAlogin(this.tfaobj).subscribe((data)=>{
      if(data){
        window.location.href="/dashboard";
      }
    },(err)=>{
      console.log(err);
      if (this.storageService.getLanguage()=='es') {
        this.notifyService.showError("", err.error.messagees)
      }else{
        this.notifyService.showError("", err.error.message)
      }
    })
}
checkNumber(chknum){
  if(isNaN(chknum)){
    this.tfaobj.tfa=''
  }
}
}
