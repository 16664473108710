import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SocketWrapperService } from './socket-wrapper.service';

/*
    Fields to save user details into database
 */
 export interface UserPayload {
     name?: String,
     userid?: Number,
     email?: String,
     phone?: Number,
     password?: String,
     passwordrepeat?:String,
     agree?:Boolean,
     dob?: Date,
     address?: String,
     city?: String,
     state?: String,
     country?: String,
     postalcode?: Number,
     email_verified?:Number,
     // phone_verified?:Number,
     group?: String,
     gender?:String,
     secret?:String,
     _csrf?:String,
     // full_phone?:Number,
     liquidity?:Object
 }


/*
    specific format of create user call reponse
 */
export interface BankPayload {
    _id?:string;
    userId?: String,
    email?: String,
    bankname?: String,
    accountname?: String,
    accountnumber?: Number,
    swiftcode?: String,
    ifsccode?: String,
    address?: String,
    city?: String,
    state?: String,
    country?: String,
    pincode?: String,
    status?:Boolean,
    authflag: Boolean,
    _csrf: String
}

export interface UserDetails {
    _id: string;
    email: string;
    name: string;
    userid: number;
    group: string;
    exp: number;
    iat: number;
}

export interface ProfilePayload {
    user_id: string,
    _csrf: string
    email?: String;
    name?: String;
    nickname?: String;
    country?: any;
    state?: String;
    address?: String;
    legalname?: String;
    pin?: String;
    language?: String;
    timezone?: String;
    phone?:Number;
    month?:String;
    day?:String;
    authflag:Boolean;
    phonecode?:String;
    countrycode?:String;
    year?:String;
    date?:String;
    userid?:Number;
    profileimage:string;
    profileImage:string;
    confirmpassword:string;
    password:string;
    currentpassword:string;
    dob:string;
    id_proof:string;
    idproof:string;
    ubo_name: String;
    ubo_dob:string;
    ubo_phonecode?:String;
    ubo_phone?:Number;
    ubo_email?:String;
    ubo_address?:String;
    ubo_country?:any;
    ubo_idproof?:String;
    ubo_addressproof?:String;
    ubo_check?:any;
    address_proof?:String;
  }

/*
    interface for token
 */
interface TokenResponse {
    token: string;
}


/*
    Fields to save user details into database
 */
export interface TokenPayload {
    email?: string;
    password?: string,
    authflag: Boolean,
    _csrf: String,
    tfa_status:Number,
    tfa:Number
    captcha?:any
}
export interface kycupload {

  _csrf: String
  front?: String;
  back?: String;
  pan?: String;
  company_reg?: String;
  authflag:Boolean;
  board_member_id:String;
  residprooffront?: String;
  residproofback?: String;
  resaddressproof?: String;
  resphotoproof?: String;
  aoa?:String;
  bank_accn?:String;
  video_selfie?:String;
  source_funds?:String;
  appln_questionnaire?:String;
  addn_infor?:String;
  letter_of_auth?:String
}

@Injectable()
export class UserService {
    /*
        back end application api url
     */
    api_url: string = environment.apiEndPoint;
    wallet_url: string = environment.walletserviceurl;
    private token: string;
    private userSocketDetails: string;
    constructor(private http: HttpClient, private router: Router,private ngxService: NgxUiLoaderService,private socket: SocketWrapperService) {
    }
    /*
        save token into localStorage as a item with specific key
     */
    private saveToken(token: string): void {
        localStorage.setItem('mean-token', token);
        this.socket.disconnect();
        setTimeout(()=>{
            this.socket.ioSocket.io.opts.query = { token: localStorage.getItem('mean-token') }
            this.socket.connect();
        },2000);
    }
    /*
        call for fetch token from localStrogae
     */
    public getToken(): string {
        let temp_token = localStorage.getItem('mean-token');
        if (!this.token)
            this.token = temp_token;

        if (this.token == temp_token) {
            return temp_token;
        }
        return '';
    }

    /*
        fetch user details for sicket
     */
    public getUserSocketDetails() {
        //this.getIpvfourClient().subscribe((ipres)=>{
        //var ipres = await this.getIpvfourClient().toPromise();
        var userSocketDetails = {
            //ip: ipres.ip,
            platform: window.navigator.platform,
            userAgent: window.navigator.userAgent,
        }
        // console.log(ipres.ip);
        // console.log(window.navigator.platform)
        // console.log(window.navigator.userAgent)
        this.userSocketDetails = JSON.stringify(userSocketDetails);
        return this.userSocketDetails;

        //})
        //return this.userSocketDetails;
    }

    /*
        fetch user token details
     */
    public getUserDetails(): UserDetails {
        const token = this.getToken();
        let payload;
        if (token) {
            payload = token.split('.')[1];
            payload = window.atob(payload);
            return JSON.parse(payload);
        } else {
            return null;
        }
    }

    /*
        fetch user token details
     */
    public getUserId(): string {
        var userDetails = this.getUserDetails();
        if (userDetails) {
            return userDetails._id.toString();
        } else {
            return null;
        }
    }

    public getReferralUserById(id): Observable<any> {
        return this.userequest('get', 'getReferralUserById/' + id,);
    }

    public updateReferralUserById(data): Observable<any> {
        return this.userequest('post', 'updateReferralUserById', data, {withCredentials: true});
    }  

    /*
        call for check the user session
     */
    public isLoggedIn(): boolean {
        const user = this.getUserDetails();
        if (user) {
            return user.exp > Date.now() / 1000;
        } else {
            return false;
        }
    }
    /*
        all type of api call handlers at client side and send token in header in all GET api call to verify valid user
        application at back end match user token with this token, if both token are match means this is a valid user otherwise
        return with a exception invalid user
     */
    public request(method: 'post' | 'get', type, user?: TokenPayload, paramslist?): Observable<any> {
        let base;

        if (method === 'post') {
            if (type === 'login') {
                base = this.http.post<any>(this.api_url + type, user,{withCredentials: true});
            }

            else {
                base = this.http.post<any>(this.api_url + type, user,{withCredentials: true});

                // base = this.http.post<any>(this.api_url + type, user, { headers: { Authorization: `Bearer ${this.getToken()}` }, withCredentials: true });
            }
        } else {
            if (type === 'kycplanbyid') {
                base = this.http.get<any>(this.api_url + type, { headers: { Authorization: `Bearer ${this.getToken()}` }, params: paramslist, withCredentials: true});

            }else{

            }
            base = this.http.get<any>(this.api_url + type, { headers: { Authorization: `Bearer ${this.getToken()}` }, params: paramslist, withCredentials: true});
        }
        const request = base.pipe(
            map((data: TokenResponse) => {
                if (data !== null && data.token) {
                    this.saveToken(data.token);
                }
                return data;
            })
        );

        return request;
    }

    /* User Request */

    public resetEmail(id,lang): Observable<any> {
        return this.userequest('get', 'sendfpwcode', null, { email: id,lang: lang})
    }

    public userequest(method: 'post' | 'get', type, user?, paramslist?): Observable<any> {
        if(type=="setforgetpasswordal"){
            return this.http.post<any>(this.api_url + type, paramslist, {
                withCredentials: true,
                headers: {Authorization: `Bearer ${this.getToken()}`}
            });
        }
        if(type=="setforgetpassword"){
            return this.http.post<any>(this.api_url + type, paramslist, {
                withCredentials: true,
                headers: {Authorization: `Bearer ${this.getToken()}`}
            });
        }
        if (method === 'post') {
            return this.http.post<any>(this.api_url + type, user, {
                withCredentials: true,
                headers: {Authorization: `Bearer ${this.getToken()}`}
            });

        }
        if (type === 'kycplanbyid') {
            return this.http.post<any>(this.api_url + type, user, {
                withCredentials: true,
                headers: {Authorization: `Bearer ${this.getToken()}`}
            });

        }
        else {
            return this.http.get<any>(this.api_url + type, {
                headers: {Authorization: `Bearer ${this.getToken()}`},
                withCredentials: true,
                params: paramslist
            });

        }
    }
    /*
        api call using post mether for csrf
     */
    public csrf(): Observable<any> {
        return this.http.get<any>(this.api_url + 'csrf',{withCredentials: true});
    }
    public getDionBook(symbol): Observable<any> {
        return this.http.get<any>(this.api_url + 'getDionBook/'+symbol,{withCredentials: true});
    }
   
    public gtwalletcsrf(): Observable<any> {
        return this.http.get<any>(this.wallet_url + 'csrf', {withCredentials: true});
    }

    public getBlocksizeReq(cur): Observable<any> {
        return this.http.get<any>(this.api_url + 'blocksizeReq/'+cur, {withCredentials: true});
    }

    public getBlocksize(a,b): Observable<any> {
        if(a=='VWAP'){
            var curUrl = 'https://api.blocksize.capital/v1/data/vwap/latest/'+b+'/1s';
        } else if(a=='OHLC'){
            var curUrl = 'https://api.blocksize.capital/v1/data/ohlc/latest/'+b+'/1s';
        }

        if(a=='VWAPtf'){
            var curUrl = 'https://api.blocksize.capital/v1/data/vwap/latest/'+b+'/60s';
        } else if(a=='OHLCtf'){
            var curUrl = 'https://api.blocksize.capital/v1/data/ohlc/latest/'+b+'/60s';
        }
        const headers_object = {headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'X-API-KEY': 'B9TsxjABRezlYxtzw0TtMZEhnk3GStSB10QpX8wc4g76uELgTIzrLFnuXDFPDcX4XmO3jZqd8KT2cWhBNv9Xm7CkyLBZkPpTMWNT0DmkKtRDLtQvFKzhb5IHlzm1Id0X'})};
          return this.http.get<any>(curUrl, headers_object).pipe(
        );
      }

    public getBlocksizeorderbook(a,b): Observable<any> {
        var curUrl = 'https://api.blocksize.capital/v1/data/orderbook?exchanges=KRAKEN&ticker='+b+'&limit=100';
        const headers_object = {headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'X-API-KEY': 'B9TsxjABRezlYxtzw0TtMZEhnk3GStSB10QpX8wc4g76uELgTIzrLFnuXDFPDcX4XmO3jZqd8KT2cWhBNv9Xm7CkyLBZkPpTMWNT0DmkKtRDLtQvFKzhb5IHlzm1Id0X'})};
        return this.http.get<any>(curUrl, headers_object).pipe(
        );
    }

     public Blocksizeorderplacing(a,b): Observable<any> {
        var body = {
            "token_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "base_currency": "BTC",
            "quote_currency": "USD",
            "quantity": 0.001,
            "direction": "BUY",
            "type": "MARKET",
            "exchange_list": [
                  "KRAKEN",
                  // "COINBASE"
             ]
        }
        var curUrl = 'https://api.blocksize.capital/v1/trading/orders';
        const headers_object = {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-API-KEY': 'B9TsxjABRezlYxtzw0TtMZEhnk3GStSB10QpX8wc4g76uELgTIzrLFnuXDFPDcX4XmO3jZqd8KT2cWhBNv9Xm7CkyLBZkPpTMWNT0DmkKtRDLtQvFKzhb5IHlzm1Id0X'})};
        return this.http.post<any>(curUrl, body, headers_object).pipe();
    }

    /*
        api call for login user
     */
    public login(user: TokenPayload,lang): Observable<any> {
        return this.request('post', 'userlogin?lang='+lang, user);
    }
    public addIpAddrclient(object): Observable<any> {
        return this.request('post', 'addipaddrclient',object);
    }
    public TFAlogin(user):Observable<any>{
        return this.request('post','tfalogin',user)
    }
    public checkPasswordLink(data): Observable<any> {
        return this.request('post', 'checkpasswordgeneration',  data )
    }
    public updateprofile(profile: ProfilePayload): Observable<any> {
        return this.userequest('post', 'updateprofile', profile,{withCredentials: true});
    }    
    public delWhitelist(obj, lang): Observable<any> {
        return this.userequest('post', 'delwhitelist?lang='+lang, obj, { withCredentials: true });
    }    

    public apiConfigs(): Observable<any> {
        return this.userequest('get', 'getapiconfig_userid', {withCredentials: true});
    }    
    public apiConfig(id): Observable<any> {
        return this.userequest('get', 'apiconfig/' + id, {withCredentials: true});
    }    
    public deleteApiConfig(obj): Observable<any> {
        return this.userequest('get', 'delapiconfig/' + obj.id, {withCredentials: true});
    }    
    public saveApiConfig(data): Observable<any> {
        return this.userequest('post', 'saveapiconfig', data, {withCredentials: true});
    }    

    public updateReferral(user): Observable<any> {
        return this.userequest('post', 'updateReferral', user,{withCredentials: true});
    }   

    public updateuserpassword(profile: ProfilePayload,lang): Observable<any> {
        return this.userequest('post', 'updateuserpswd'+"?lang="+lang, profile,{withCredentials: true});
    }  
    public addGetinTouch(Data): Observable<any> {
        return this.userequest('post', 'addgetintouch', Data,{withCredentials: true});
    }  
    postFile(fileToUpload: File,csrf): Observable<any> {
        
        const formData = new FormData();
        formData.append('profileimage', fileToUpload);
        // console.log(formData.get('profileimage'));
        return this.userequest('post','updateprofileimage'+"?_csrf="+csrf,formData)
    }   
    public kycSave(doc: kycupload): Observable<any> {
        return this.userequest('post', 'kycupdate', doc);
    }
    public findkyc(id): Observable<any> {
        return this.userequest('get', 'getcustomerkyc',id);
    }
    public uploadDocn(fileToUpload: File,key,csrf): Observable<any> {
        const formData = new FormData();
        formData.append(key, fileToUpload);
        // console.log(formData.get('profileimage'));
        return this.userequest('post','uploaddocument'+"?_csrf="+csrf,formData)
        
        // return this.userequest('post', 'uploaddocument', doc);
    }
    public setPassword(data): Observable<any> {
        return this.userequest('post', 'setpassword',  data )
    }
    public getkyc(): Observable<any> {
        return this.request('get', 'getkyc');
    }
    public getfiatwithdrawlistbyuserid(obj,perpage,page,currency,expvalue): Observable<any> {
        return this.request('get', 'getfiatwithdrawbyuserid',null,{obj,perpage:perpage,page:page,currency:currency
            ,export:expvalue});
    }
    public getcurrency(): Observable<any> {        
        return this.userequest('get', 'currency?type=crypto');
    }
    public getCurrencies(): Observable<any> {        
        return this.userequest('get', 'getcurrencies');
    }
    public getBanks(): Observable<any> {        
        return this.userequest('get', 'getbanks');
    }
    public getfiatcurrency(): Observable<any> {        
        return this.userequest('get', 'currency?type=fiat');
    }
    public getNotification(obj):Observable<any>{        
        return this.userequest('get','getbellnotificationbyid',null,obj);
    }
    public getProfileAPI(): Observable<any> {
        return this.request('get', 'getprofile');
    }
    public getReferralsAPI(perpage,page,search): Observable<any> {
        return this.userequest('get', 'getUserReferrals',null,{perpage:perpage,page:page,search:search})
    }

    public getReferralBonusAPI(perpage,page,search): Observable<any> {
        return this.userequest('get', 'getUserReferralstrans',null,{perpage:perpage,page:page,search:search})
    }
    public sendotp(): Observable<any> {
        return this.request('get', 'sscode');
    }
    public getloginlog(data): Observable<any>{
        return this.request('get','findloginlog',null,data)
    }
    public showlogin(): Observable<any> {
        return this.request('get', 'showlogin');
        // return this.http.get<any>(this.wallet_url + 'showlogin', {withCredentials: true});
    }
    // public saveUser(user): Observable<any> {
    //       return this.http.post<any>(this.api_url + type, user, {
    //             withCredentials: true,
    //             headers: {Authorization: `Bearer ${this.getToken()}`}
    //         });
    //     return this.request('post', 'registration', user);
    //     // return this.http.post<any>(this.wallet_url + 'registration', {withCredentials: true});
    // }

    public getlanguagelist(): Observable<any> {
        return this.http.get<any>("assets/json/language.json");
    }
    public timezone(): Observable<any> {
        return this.http.get<any>("assets/json/timezone.json");
    }

    public getState(id): Observable<any> {
        return this.userequest('post','getState',id)
    }
    public getMobileCode(id): Observable<any> {
        return this.userequest('post','mobilecode',id)
    }
    public country(): Observable<any> {
        return this.userequest('get','countrylist');
        // return this.http.get<any>("get",'countrylist');
    }
    public filterstate(id): Observable<any> {
        return this.request('post','getState');
        // return this.http.get<any>("get",'countrylist');
    }

    /*
      api call for add new customer
   */
    public addNewCustomer(user: UserPayload): Observable<any> { console.log(user)
        return this.userequest('post','registration',user)
    }
    public resetPassword(req): Observable<any> { console.log(req)
        return this.userequest('post','vryfrpasscode',req)
    }

    /*
        logout the user and destroy the session of user
     */
    public logout(): void {
        //localStorage.clear();
        localStorage.removeItem("mean-token");
        this.token = '';
        this.router.navigate(['login']);
    }
    /*
        api call for get customer list
     */
    public getcustomerList(perpage,page,search):Observable<any> {
        return this.request('get','customer', null, {perpage:perpage,page:page,search:search});
    }

    /*
    * api call for active customer */
    public getCustomerDetailsById(userId):Observable<any>{
        return this.request('get','customerbyid',null,{id:userId});
    }

    public verifyMailId(userId):Observable<any>{
        return this.request('get','verifymailid',null,userId);
    }
    /*
    * api call for active customer */
    public setActive(userId):Observable<any>{
        return this.request('get','custstatusupdate',null,{id:userId,status:"Active"});
    }

    /*
    * api call for deactive customer */
    public setDeactive(userId):Observable<any>{
        return this.request('get','custstatusupdate',null,{id:userId,status:"Deactive"});
    }

    /*
    * api call for delete customer */
    public customerDelete(userId):Observable<any>{
        return this.request('get','deletecustomer',null,{id:userId});
    }
    /*
    * api call for delete customer */
    public getLoginActivityList(userId,perpage,page):Observable<any>{
        return this.request('get','getloginlogs',null,{uid:userId,perpage:perpage,page:page});
    }
    
    public saveAntiPhisCode(req,lang): Observable<any> {
        return this.userequest('post','saveantiphiscode?lang='+lang,req)
    }
    public getAntiPhisCode(req):Observable<any>{
        return this.request('get','getantiphiscode',null,req);
    }
    public updateTfa(req,lang): Observable<any> {
        return this.userequest('post','updateTfa?lang='+lang,req)
    }
    startLoader(){
        this.ngxService.start();
    }
    stopLoader(){
         this.ngxService.stop();
    }
    getIpvfourClient():Observable<any>{
        return this.http.get("https://api.ipify.org/?format=json")
    }
    getIpvsixClient():Observable<any>{
        return this.http.get("https://jsonip.com")

    }
    public getCMS(object): Observable<any> {
        return this.userequest('get', 'getcms',null,object);
    }
    getbtclive():Observable<any>{
        return this.http.get("https://min-api.cryptocompare.com/data/v2/histohour?fsym=BTC&tsym=EUR&limit=5")
        // return this.http.get("https://api.nomics.com/v1/currencies/ticker?key=cf603ea4c4df4cd84c1c8ecd5ce171a0&ids=BTC,ETH&interval=1d,30d&convert=EUR&per-page=10&page=1")

         
        // return this.http.get('https://www.alphavantage.co/query?function=CURRENCY_EXCHANGE_RATE&from_currency=BTC&to_currency=EUR&apikey=MIX93213R84R24Z9')
    }

    public addBalanceRequest(data): Observable<any> {      
        return this.http.post<any>(this.api_url + 'addBalanceRequest', data, {
          withCredentials: true,
          headers: {'Content-Type':  'application/json',Authorization: `Bearer ${this.getToken()}`}
        });
    }

    public createAddress(data): Observable<any> {      
        return this.http.post<any>(this.api_url + 'createAddress', data, {
          withCredentials: true,
          headers: {'Content-Type':  'application/json',Authorization: `Bearer ${this.getToken()}`}
        });
    }

    public createWallet(data): Observable<any> {      
        return this.http.post<any>(this.api_url + 'createWallet', data, {
          withCredentials: true,
          headers: {'Content-Type':  'application/json',Authorization: `Bearer ${this.getToken()}`}
        });
    }

    public reduceBalanceRequest(data,lang): Observable<any> {      
        return this.http.post<any>(this.api_url + 'fiatwithdraw?lang='+lang, data, {
          withCredentials: true,
          headers: {'Content-Type':  'application/json',Authorization: `Bearer ${this.getToken()}`}
        });
    }
    
    public createManageBank(data): Observable<any> {      
        return this.http.post<any>(this.api_url + 'createManageBank', data, {
          withCredentials: true,
          headers: {'Content-Type':  'application/json',Authorization: `Bearer ${this.getToken()}`}
        });
    }

    public getBankAccounts(): Observable<any> {
        return this.userequest('get', 'getBankAccounts',);
    }

    // Kyc save draft
    public savedraftKyc(doc: kycupload): Observable<any> {
        return this.userequest('post', 'savedraftKyc', doc);
    }
    public getSumsubAccessToken(obj) {
        return this.userequest('post', "sumsub_token", obj);
    }
    public ApplicantDataFromSumsub(userId): Observable<any> {
        return this.userequest('get', `applicant_data/${userId}`);
    }
    public saveApplicantData(obj): Observable<any> {
        return this.http.post(this.api_url + 'sumsub', obj,  {
            withCredentials: true,
            headers: {'Content-Type':  'application/json',Authorization: `Bearer ${this.getToken()}`}
          });
    }
    public kycUpdation(): Observable<any> {
        return this.request('get', 'kycUpdation');
    }
    public getbasis_id(): Observable<any> {
        return this.request('get', 'getbasis_id');
    }
    public getAllCurrencies(type): Observable<any> {
        return this.userequest('get', 'getcurrencylist', null, {type: type});
    }

    public getMarketPrices(): Observable<any> {
        return this.userequest('get', 'getmarketprices',);
    }
    // public getpPairbyId(obj): Observable<any> {
    //     return this.userequest('get', 'getpairbycurrency', null, obj);
    // }

    public getLedgerlistbyuserid(obj,perpage,page,currency,Type,walletType,expvalue): Observable<any> {
        return this.request('get', 'getledgerlistbyuserid',null,
        {obj,perpage:perpage,page:page,
            currency:currency,
            Type:Type,
            walletType:walletType,
            export:expvalue});
    }

    public getCollateralDepositlistbyuserid(obj,perpage,page,currency,Type,expvalue): Observable<any> {
        return this.request('get', 'getCollateralDepositbyuserid',null,{obj,perpage:perpage,page:page,currency:currency
            ,Type:Type,export:expvalue});
    }

    public getAllBlockchainConfig(): Observable<any> {
        return this.userequest('get', 'getAllBlockchainConfig',);
    }
    public saveReferralUser(obj): Observable<any> {
        return this.userequest('post', 'referral-user', obj);
    }
}
