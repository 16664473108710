import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TopHeaderComponent } from './top-header/top-header.component';
import { HomeComponent } from './home/home.component';

import { HomeHeaderComponent } from './home-header/home-header.component';
import { BlockComponent } from './block/block.component';

import { LinkexpireComponent } from './linkexpire/linkexpire.component';
import { KycComponent } from './kyc/kyc.component';
import { SubmitComponent } from './submit/submit.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ProfileComponent } from './profile/profile.component';
import { GeneratePasswordComponent } from './generate-password/generate-password.component';

import { TradeComponent } from './trade/trade.component';
import { CreditLimitComponent } from './credit-limit/credit-limit.component';

import { TransferComponent } from './transfer/transfer.component';
import { DepositComponent } from './deposit/deposit.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ReferralComponent } from './referral/referral.component';
import { EditReferralComponent } from './referral/edit-referral/edit-referral.component';

import { SignupComponent } from './signup/signup.component';
import { ContentComponent } from './content/content.component';
import { CareersComponent } from './careers/careers.component';
import { JobdescriptionsComponent } from './jobdescriptions/jobdescriptions.component';
import { SuccessComponent } from './kyc/success/success.component';
import { ZignsecFailureComponent } from './zignsec-failure/zignsec-failure.component';
import { LedgerComponent } from './ledger/ledger.component';
import { ApiconfigManagementComponent } from './apiconfig-management/apiconfig-management.component';
import { NewApiconfigManagementComponent } from './new-apiconfig-management/new-apiconfig-management.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CreditDashboardComponent } from './credit-dashboard/credit-dashboard.component';

import { AuthGuard } from './auth/auth.guard';
import { CollateralDepositComponent } from './collateral-deposit/collateral-deposit.component';


const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: 'home', component: HomeComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/:id', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password/:id', component: ForgotPasswordComponent },
  { path: 'reset', component: ResetpasswordComponent },
  { path: 'reset/:id', component: ResetpasswordComponent },
  { path: 'block', component: BlockComponent },
  { path: 'linkexpire', component: LinkexpireComponent },
  { path: 'generatepassword/:id', component: GeneratePasswordComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'jobdescriptions/:id', component: JobdescriptionsComponent },
  { path: 'success', component: SuccessComponent },
  { path: 'failed', component: ZignsecFailureComponent },
  {
    path: '', component: ContentComponent, 
    children: [
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
      { path: 'apiconfig', component: ApiconfigManagementComponent, canActivate: [AuthGuard] },
      { path: 'newapiconfig', component: NewApiconfigManagementComponent, canActivate: [AuthGuard] },
      { path: 'newapiconfig/:id', component: NewApiconfigManagementComponent, canActivate: [AuthGuard] },
      { path: 'SideNavComponent', component: ProfileComponent, canActivate: [AuthGuard] },
      { path: 'home-header', component: HomeHeaderComponent, canActivate: [AuthGuard] },
      { path: 'kyc/:id', component: KycComponent, canActivate: [AuthGuard] },
      { path: 'kyc', component: KycComponent, canActivate: [AuthGuard] },
      { path: 'submit', component: SubmitComponent, canActivate: [AuthGuard] },

      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'credit-dashboard', component: CreditDashboardComponent, canActivate: [AuthGuard] },
      
      { path: 'trade/:pair', component: TradeComponent, canActivate: [AuthGuard] },
      { path: 'credit-limit/:pair', component: CreditLimitComponent, canActivate: [AuthGuard] },
      
      { path: 'withdraw', component: TransferComponent, canActivate: [AuthGuard] },
      { path: 'referral', component: ReferralComponent, canActivate: [AuthGuard] },
      { path: 'edit-referral/:id', component: EditReferralComponent, canActivate: [AuthGuard] },
      { path: 'withdrawsubmit/:id', component: TransferComponent, canActivate: [AuthGuard] },
      { path: 'withdrawcancel/:id', component: TransferComponent, canActivate: [AuthGuard] },
      { path: 'whitelist/:id', component: TransferComponent, canActivate: [AuthGuard] },
      { path: 'deposit', component: DepositComponent, canActivate: [AuthGuard] },
      { path: 'deposit/:cur', component: DepositComponent, canActivate: [AuthGuard] },
      { path: 'ledger', component: LedgerComponent, canActivate: [AuthGuard] },
      { path: 'collateral-deposit', component: CollateralDepositComponent, canActivate: [AuthGuard] },
      { path: '**', redirectTo: 'profile' },
      { path: '', redirectTo: 'profile', pathMatch: 'full' },

    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

export const routingComponents =
  [
    ProfileComponent,
    SideNavComponent,
    TopHeaderComponent,
    HomeHeaderComponent,
    LoginComponent,
    ResetpasswordComponent,
    TradeComponent,
    ReferralComponent,
    TransferComponent
  ];
