// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
    production: false,
    name:'production',
    apiEndPoint:'https://api.blockoville.com/apiroute/',
    zignsecSuccessUrl:'https://otcadmin.blockoville.com:82/success',
    zignsecFailedUrl:'https://otcadmin.blockoville.com:82/failed',
    walletserviceurl:  'https://api.blockoville.com/',
    zignsecApiKey:'da615ba7aae34ecea38da29491e82c6c',
    imageapiendpoint:'https://api.blockoville.com/uploads/',
    S3_BUCKET_URL: 'https://blockoville-otc.s3.eu-central-1.amazonaws.com/',
    recaptchsitekey: '6Le6eW4UAAAAAFFTQqLmvxFGMNhp8Xbs1JDwV3ot',
    SOCKET_SERVER : 'https://api.blockoville.com/',
    socketurl : 'https://api.blockoville.com/',
    socketurlexchange : 'https://api.blockoville.com/',
    home: 'https://blockoville.com/',
    walletConnectURL: 'https://otc-deposit.blockoville.com/',
    wcSupportedChains: {
      "Ethereum": 1,
      "Dogecoin": 2000,
      "Ethereum Classic": 61,
      "XinFin Network": 50,
      "Polygon": 137,
      "BNB Smart Chain": 56
    }
  };
  
  