import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ClipboardService } from 'ngx-clipboard'
import { DatePipe } from '@angular/common';
import { UserService } from "../services/user.service";
import { TradingService } from '../services/trading.service';
import { NotificationService } from '../notification/notification.service';
import * as XLSX from 'xlsx';
import { NgForm } from '@angular/forms';
import { KycserviceService } from '../services/kycservice.service'
import { StorageService } from '../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { SocketService } from 'src/app/services/socket.service';
import { SocketWrapperService } from '../services/socket-wrapper.service';
import { AgGridAngular } from 'ag-grid-angular';
import * as moment from 'moment';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.css'],
  styles: [`
    .ag-theme-balham .ag-cell-data-changed {
      background-color:#d1d9ff !important;
    }
    .ag-header-row.ag-header-row-column{
      color:black;
    }
  `],
  encapsulation: ViewEncapsulation.None
})

export class DepositComponent implements OnInit {

  referenceCode: any;
  //Order History Ag Grid
  public walletConnectEnabled: boolean = true;

  public cyptoDepositColumnDefs: ColDef[] = [
    { field: 'txnid', headerName: 'TXN ID', width: 300 },
    { field: 'txHash', headerName: 'TXN Hash', flex: 1 },
    { field: 'currency', headerName: 'Currency', width: 100 },
    { field: 'amount', headerName: 'Amount', width: 120 },
    { field: 'fee', headerName: 'Fee', width: 120 },
    { field: 'address', headerName: 'Address', flex: 1 },
    {
      field: 'createdAt', headerName: 'Created AT', sort: 'desc', width: 200,
      valueFormatter: param => {
        if (param.value) {
          return moment(param.value).format('YYYY-MM-DD HH:mm:ss a');
        }
        return '';
      }
    },
    { field: 'status', headerName: 'Status', width: 100 },
  ];
  public cyptoDepositDefaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    enableCellChangeFlash: true
  };
  public cyptoDepositRowData: any[] = [];
  @ViewChild(AgGridAngular) cyptoDepositAgGrid: AgGridAngular;
  cyptoDepositGridApi!: GridApi;


  public cyptoDepositHistoryColumnDefs: ColDef[] = [
    { field: 'txnid', headerName: 'TXN ID', width: 300 },
    { field: 'txHash', headerName: 'TXN Hash', flex: 1 },
    { field: 'currency', headerName: 'Currency', width: 100 },
    { field: 'amount', headerName: 'Amount', width: 120 },
    { field: 'fee', headerName: 'Fee', width: 120 },
    { field: 'address', headerName: 'Address', flex: 1 },
    {
      field: 'createdAt', headerName: 'Created AT', sort: 'desc', width: 200,
      valueFormatter: param => {
        if (param.value) {
          return moment(param.value).format('YYYY-MM-DD HH:mm:ss a');
        }
        return '';
      }
    },
    { field: 'status', headerName: 'Status', width: 100 },
  ];
  public cyptoDepositHistoryDefaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    enableCellChangeFlash: true
  };
  public cyptoDepositHistoryRowData: any[] = [];
  @ViewChild(AgGridAngular) cyptoDepositHistoryAgGrid: AgGridAngular;
  cyptoDepositHistoryGridApi!: GridApi;

  //FIAT CURRENCY

  public fiatDepositHistoryColumnDefs: ColDef[] = [
    { field: 'otctxnid', headerName: 'Blockoville txnID', width: 150 },
    { field: 'txnid', headerName: 'Trans Ref ID', width: 250 },
    { field: 'bank_account_id.bank_name', headerName: 'Bank Name', flex: 1 },
    { field: 'currency', headerName: 'Currency', width: 100 },
    { field: 'amount', headerName: 'Amount', width: 100 },
    { field: 'fee', headerName: 'Fee', width: 100 },
    { field: 'note', headerName: 'Note', width: 100 },
    { field: 'status', headerName: 'Status', width: 100 },
    {
      field: 'createdAt', headerName: 'Created AT', sort: 'desc', flex: 1,
      valueFormatter: param => {
        if (param.value) {
          return moment(param.value).format('YYYY-MM-DD HH:mm:ss a');
        }
        return '';
      }
    }
  ];
  public fiatDepositHistoryDefaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    enableCellChangeFlash: true
  };
  public fiatDepositHistoryRowData: any[] = [];
  @ViewChild(AgGridAngular) fiatDepositHistoryAgGrid: AgGridAngular;
  fiatDepositHistoryGridApi!: GridApi;
  S3_BUCKET_URL = environment.S3_BUCKET_URL;

  currencyActiveCurrencyType = "CRYPTO"; //default tab selection..

  cryptoCurrencyActive: any = {};
  cryptoCurrencyDefault: string = "BTC";
  cryptoCurrencyHide: string = "ECLIP";
  //  isCryptoWallet: boolean = false;

  fiatCurrencyActive: any = {};
  fiatCurrencyDefault: string = 'EUR';
  //  isFiatWallet: boolean = false;

  cryptoCurrencies: any = [];
  fiatCurrencies: any = [];

  cryptoZeroBalance: boolean = false;
  fiatZeroBalance: boolean = false;

  wallets: any = [];
  cryptoWallets: any = [];
  filteredCryptoWallets: any = [];
  fiatWallets: any = [];
  
  bankAccounts: any = [];

  searchTermCrypto = "";
  searchTermFiat = "";
  cryptoSearchCopy: any = [];
  fiatSearchCopy: any = [];

  banks: any = [];
  bankActive: any = {};

  transObj: any = {};
  createWalletObj: any = {};

  formManageBank: any = {
    "firstName": "",
    "lastName": "",
    "middleName": "",
    "mobileNumber": "",
    "panNo": "",
    "ifscCode": "",
    "remitterAccountNumber": ""
  };

  // public deposithistorylist: any = [];
  public fiatdepositlist: any = [];

  public exporthistorylist: any = [];
  public fiatDepositExport: any = [];

  blockchainConfig: any = [];
  activeBlockchain: any = [];
  blockchain: any = {};
  selectedBlockchain: any = {};

  userId: string = '';
  updatedWallet: any = {};
  view_proof = "no file choosen";

  public data: any = [];
  public fileToUpload: File = null;

  searchObj = {
    currency: '',
  }

  constructor(
    private _clipboardService: ClipboardService,
    private userService: UserService,
    private router: Router,
    private tradeService: TradingService,
    private notifyService: NotificationService,
    private actroute: ActivatedRoute,
    private kycService: KycserviceService,
    private storageService: StorageService,
    public translate: TranslateService,
    private readonly socketService: SocketService,
    private socketWrapper: SocketWrapperService,
  ) {
    this.userId = this.userService.getUserId();

    this.kycService.getUserKYC().subscribe(res => {
      if (res.status == 'Pending' || res.status == 'Rejected' || res.status == 'Draft') {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", "Pole juurdepääsetav enne KYC kinnitamist")
        } else {
          this.notifyService.showError("", "Not Accesible until KYC Verification")
        }
        this.router.navigate(['profile'])
      }
    }, (err) => {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", "Pole juurdepääsetav enne KYC kinnitamist")
      } else {
        this.notifyService.showError("", "Not Accesible until KYC Verification")
      }
      this.router.navigate(['profile'])
      this.router.navigate(['profile'])
    })
    setTimeout(() => {
      this.socketService.subscribeWalletDataChannels(this.userId);
    }, 1000);
  }

  ngOnInit(): void {
    this.data = {};
    this.transObj['depProof'] = "no file choosen";
    this.csrf();
    this.paymentCsrf();
    this.getCurrenciesDetails();
    this.getAllBlockchainConfig();
    this.getBankAccounts();
    this.socketService.onWalletDataReceive().subscribe(updatedWallet => {
      this.updatedWallet = updatedWallet;
      console.log("updatedWallet", this.updatedWallet);

      this.updatedWallet.availableBalance = this.updatedWallet.availableBalance ? parseFloat(this.updatedWallet.availableBalance).toFixed(5) : 0.00000;

      var foundCryptoWallet = this.cryptoWallets.findIndex(x => x._id == this.updatedWallet._id);
      var foundFiatWallet = this.fiatWallets.findIndex(x => x._id == this.updatedWallet._id);

      if (this.updatedWallet.currency_type == 'CRYPTO') {
        if (foundCryptoWallet >= 0) {
          this.cryptoWallets[foundCryptoWallet].availableBalance = this.updatedWallet.availableBalance;
          this.cryptoSearchCopy[foundCryptoWallet].availableBalance = this.updatedWallet.availableBalance;
        } else {
          this.cryptoWallets = [this.updatedWallet, ...this.cryptoWallets];
          this.cryptoSearchCopy = [this.updatedWallet, ...this.cryptoSearchCopy];
        }
        if (this.cryptoCurrencyActive.currency == this.updatedWallet.currency) {
          this.cryptoCurrencyActive = this.updatedWallet;
          this.changeCryptoCurrency(this.updatedWallet.currency);
        }
      }

      if (this.updatedWallet.currency_type == 'FIAT') {
        if (foundFiatWallet >= 0) {
          this.fiatWallets[foundFiatWallet] = this.updatedWallet;
          this.fiatSearchCopy[foundFiatWallet] = this.updatedWallet;
        } else {
          this.fiatWallets = [this.updatedWallet, ...this.fiatWallets];
          this.fiatSearchCopy = [this.updatedWallet, ...this.fiatSearchCopy];
        }
        if (this.fiatCurrencyActive.currency == this.updatedWallet.currency) {
          this.fiatCurrencyActive = this.updatedWallet;
          this.changeFiatCurrency(this.updatedWallet.currency);
        }
      }
    })

    this.socketWrapper.on('connect', () => {
      console.log('-------------------socket connected--------------------------');
      setTimeout(() => {
        this.socketService.subscribeWalletDataChannels(this.userId);
      }, 1000);
    });

    this.socketWrapper.on('disconnect', () => {
      console.log('-------------------socket disconnected--------------------------');
    });

    this.userService.getProfileAPI().subscribe(profile => {
      this.referenceCode = profile.bankrefcode;
    })

  }
  ngOnDestroy() {
    this.socketService.unSubscribeWalletDataConfigChannels(this.userId);
  }

  copy(data, msg) {
    this._clipboardService.copyFromContent(data)
    if (this.storageService.getLanguage() == 'es') {
      this.notifyService.showSuccess("", msg + " Kopeeritud")
    } else {
      this.notifyService.showSuccess("", msg + " Copied")
    }
  }

  csrf() {
    this.userService.csrf().subscribe((response) => {
      this.data._csrf = response._csrf;
      this.getcwaluserbalance();
      this.fetchDeposit();
      this.fetchDepositFiat();
    }, (err) => {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'Ilmnes viga, proovige uuesti.')
      } else {
        this.notifyService.showError("", 'Error occured, please try again.')
      }

    });
  }

  round(value, float = 0) {
    return parseFloat(value).toFixed(float);
  }

  dateformat(value) {
    return new DatePipe('en-Us').transform(value, 'dd-MM-yyyy HH:mm:ss', 'GMT+2');
  }

  paymentCsrf() {
    this.userService.csrf().subscribe((response) => {
      this.data._csrf = response._csrf;
    }, (err) => {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'Ilmnes viga, proovige uuesti.')
      } else {
        this.notifyService.showError("", 'Error occured, please try again.')
      }

    });
  }

  getCurrenciesDetails() {
    this.userService.getCurrencies().subscribe((response) => {
      if (response.length < 0)
        return;
      let filterData = response.filter(
        currency => currency.CurrencyType === 'CRYPTO'
          && currency.IsActive === true && currency.CurrencySymbol !== this.cryptoCurrencyHide);
      let filterFiatData = response.filter(
        currency => currency.CurrencyType === 'FIAT'
          && currency.IsActive === true);
      this.cryptoCurrencies = filterData.sort((a, b) => a.CurrencySymbol > b.CurrencySymbol ? 1 : -1);
      this.fiatCurrencies = filterFiatData.sort((a, b) => a.CurrencySymbol > b.CurrencySymbol ? 1 : -1);
    }, (err) => {
      // this.notifyService.showError("", err.error.message)
    });
  }

  createAddress() {
    this.userService.csrf().subscribe((response) => {
      if (!this.blockchain && !this.cryptoCurrencyActive.assetid)
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", 'Network is not define')
        } else {
          this.notifyService.showError("", "Network is not defined")
        }

      let createAddressObj: any = {};
      createAddressObj['_csrf'] = response._csrf;
      createAddressObj['currency'] = this.cryptoCurrencyActive.currency;
      createAddressObj['assetid'] = this.cryptoCurrencyActive.assetid;

      this.userService.createAddress(createAddressObj).subscribe((data) => {
        if (data.data) {
          this.updatedWallet = data.data;

          this.cryptoCurrencyActive.activeAddress = data.data;
          this.cryptoCurrencyActive.addresses.push(data.data);
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showSuccess("", data.messagees)
          } else {
            this.notifyService.showSuccess("", data.message)
          }
        } else {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", data.messagees)
          } else {
            this.notifyService.showError("", data.message)
          }
        }

      }, (err) => {
        console.log(err);
      })

    })
  }

  createWallet(currency_type) {
    this.userService.csrf().subscribe((response) => {
      this.createWalletObj['_csrf'] = response._csrf;
      if (currency_type == 'CRYPTO') {
        this.createWalletObj['currency'] = this.cryptoCurrencyActive.currency;
        //this.createWalletObj['assetid'] = this.cryptoCurrencyActive.assetid;
      } else if (currency_type == 'FIAT') {
        this.createWalletObj['currency'] = this.fiatCurrencyActive.currency;
      }
      this.createWalletObj['currency_type'] = currency_type;

      this.userService.createWallet(this.createWalletObj).subscribe(data => {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showSuccess("", data.messagees)
        } else {
          this.notifyService.showSuccess("", data.message)
        }
      }, (err) => {
        console.log(err);
      })

    })
  }

  getcwaluserbalance() {
    this.userService.getBanks().subscribe((response) => {
      if (response.length > 0) {
        this.banks = response;
        this.tradeService.getCwalUserBalance(this.data, 'getcwaluserbalance').subscribe((result) => {
          if (!result.wallist)
            return;
          this.wallets = result.wallist;
          for (let w = 0; w < this.wallets.length; w++) {
            this.wallets[w].availableBalance = parseFloat(this.wallets[w].availableBalance).toFixed(5)
            if (this.wallets[w].currency_type == 'CRYPTO' && this.wallets[w].currency !== this.cryptoCurrencyHide) {
              this.cryptoWallets.push(this.wallets[w]);
              this.cryptoSearchCopy.push(this.wallets[w]);
            }

            if (this.wallets[w].currency_type == 'FIAT') {
              this.fiatWallets.push(this.wallets[w]);
              this.fiatSearchCopy.push(this.wallets[w]);
            }
          }
          this.setDefaultSelection();
        }, (err) => {
          console.log(err);
        });
      }
    }, (err) => {
      console.log(err);
    });

  }

  setDefaultSelection() {
    this.actroute.queryParams.subscribe(params => {
      if (params.currencyActiveCurrencyType == "CRYPTO") {
        this.changeCryptoCurrency(params.currencyActive)
        this.currencyActiveCurrencyType = params.currencyActiveCurrencyType.toLowerCase() + "-deposit"
      } else if (params.currencyActiveCurrencyType == "FIAT") {
        this.changeFiatCurrency(params.currencyActive)
        this.currencyActiveCurrencyType = params.currencyActiveCurrencyType.toLowerCase() + "-deposit"
      } else {
        this.changeFiatCurrency(this.fiatCurrencyDefault);
        this.changeCryptoCurrency(this.cryptoCurrencyDefault);
      }
    })
  }

  changeCryptoCurrency(currency) {
    this.activeBlockchain = [];
    this.blockchain = {};
    this.cryptoCurrencyActive.activeAddress = {};

    this.cryptoCurrencyActive = this.cryptoWallets.find(e => e.currency === currency);

    if (typeof this.cryptoCurrencyActive == 'undefined') {
      this.cryptoCurrencyActive = { currency: currency, createWallet: true }
    } else {
      this.cryptoCurrencyActive.createWallet = false;
    }

    this.activeBlockchain = this.blockchainConfig.filter(
      e => (e.CurrencySymbol == currency && e.IsActive == true));
    //console.log( this.activeBlockchain);

    this.blockchain = this.blockchainConfig.find(
      e => (e.CurrencySymbol == currency && e.IsActive == true && e.IsDefault == true));

    if (this.blockchain) {

      if (this.cryptoCurrencyActive.addresses) {
        this.cryptoCurrencyActive.activeAddress = this.cryptoCurrencyActive.addresses.find(e => e.assetId === this.blockchain.FireBlockID);
      }
      this.cryptoCurrencyActive.assetid = this.blockchain.FireBlockID;
      this.selectedBlockchain = this.blockchain;
    } else {
      this.cryptoCurrencyActive.assetid = '';
      this.selectedBlockchain = {};

    }
    console.log(this.cryptoCurrencyActive)
    this.setCurrencyFilter(this.cryptoCurrencyActive?.currency);
    this.checkWalletConnectAvailability()
  }

  changeblockchain(blockchain) {
    this.blockchain = {};
    this.cryptoCurrencyActive.activeAddress = {};
    this.blockchain = this.blockchainConfig.find(
      e => (e.CurrencySymbol == this.cryptoCurrencyActive?.currency && e.IsActive == true && e.FireBlockID == blockchain));

    if (this.blockchain) {
      if (this.cryptoCurrencyActive.addresses) {
        this.cryptoCurrencyActive.activeAddress = this.cryptoCurrencyActive.addresses.find(e => e.assetId === this.blockchain.FireBlockID);
      }
      this.cryptoCurrencyActive.assetid = this.blockchain.FireBlockID;
      this.selectedBlockchain = this.blockchain;
    } else {
      this.cryptoCurrencyActive.assetid = '';
      this.selectedBlockchain = {};
    }
    console.log(this.cryptoCurrencyActive)
    this.checkWalletConnectAvailability();
  }

  changeFiatCurrency(currency) {
    this.fiatCurrencyActive = this.fiatWallets.find(e => e.currency === currency);
    if (typeof this.fiatCurrencyActive == 'undefined') {
      this.fiatCurrencyActive = { currency: currency, createWallet: true }
    } else {
      this.fiatCurrencyActive.createWallet = false
    }
    this.fiatCurrencyActive.banks = this.banks.filter(bank => bank.currency === currency);
    if (typeof this.fiatCurrencyActive.banks == 'undefined') {
      this.fiatCurrencyActive.banks = []
    } else {
      var bankActive = this.fiatCurrencyActive.banks.find(e => e.is_default === true);
      if (typeof bankActive == 'undefined') {
        this.bankActive = {};
      } else {
        this.bankActive = bankActive;
        this.fiatCurrencyActive.bank_active = bankActive._id;
      }

    }

    this.transObj['currency'] = currency;
    this.changeFiatAmount();

  }

  changeBank(id) {
    this.fiatCurrencyActive.bank_active = id;
    this.bankActive = this.fiatCurrencyActive.banks.find(e => e._id === id);
    this.changeFiatAmount();
  }

  resetFiat() {
    this.transObj['depProof'] = "no file choosen";
    this.view_proof = "no file choosen";
    this.transObj['currency'] = "USD";
    this.transObj['clearjnID'] = "";
    this.transObj['amount'] = "";
  }

  searchCrypto(): void {
    let term = this.searchTermCrypto;
    this.cryptoWallets = [];
    if (this.cryptoZeroBalance) {
      this.cryptoWallets = this.cryptoSearchCopy.filter(function (tag) {
        if (tag.currency.toLowerCase().indexOf(term.toLowerCase()) >= 0 && (Number(tag.availableBalance) > 0)) {
          return tag;
        }
      });
    } else {
      this.cryptoWallets = this.cryptoSearchCopy.filter(function (tag) {
        if (tag.currency.toLowerCase().indexOf(term.toLowerCase()) >= 0) {
          return tag;
        }
      });
    }

  }

  filterCryptoZeroBalance(event: MatCheckboxChange) {
    this.searchTermCrypto = '';
    if (event.checked) {
      this.cryptoWallets = this.cryptoSearchCopy.filter(function (item) {
        return (item && Number(item.availableBalance) > 0) ? true : false;
      });
    } else {
      this.cryptoWallets = this.cryptoSearchCopy
    }
  }

  searchFiat(): void {
    let term = this.searchTermFiat;
    this.fiatWallets = [];
    if (this.fiatZeroBalance) {
      this.fiatWallets = this.fiatSearchCopy.filter(function (tag) {
        if (tag.currency.toLowerCase().indexOf(term.toLowerCase()) >= 0 && (Number(tag.availableBalance) > 0)) {
          return tag;
        }
      });
    } else {
      this.fiatWallets = this.fiatSearchCopy.filter(function (tag) {
        if (tag.currency.toLowerCase().indexOf(term.toLowerCase()) >= 0) {
          return tag;
        }
      });
    }

  }

  filterFiatZeroBalance(event: MatCheckboxChange) {
    this.searchTermFiat = '';
    if (event.checked) {
      this.fiatWallets = this.fiatSearchCopy.filter(function (item) {
        return (item && Number(item.availableBalance) > 0) ? true : false;
      });
    } else {
      this.fiatWallets = this.fiatSearchCopy
    }
  }

  fetchDeposit(currency = null) {
    this.searchObj['currency'] = currency ?? this.searchObj['currency'];
    this.tradeService.getDeposit('', '', this.searchObj.currency, false).subscribe((result) => {
      var response = result.deposit;
      var deparr = []
      for (var i = 0; i < response.length; i++) {
        deparr.push({
          txnid: response[i].txnid,
          currency: response[i].currency,
          txHash: response[i].txHash,
          amount: response[i].amount,
          fee: response[i].fee,
          address: response[i].address,
          createdAt: response[i].createdAt,
          status: response[i].status,
        })
      }
      // this.deposithistorylist = deparr;
      this.bindCyptoHistoryGrid(deparr);
      this.bindCyptoGrid(deparr);
    }, (err) => {
      this.notifyService.showError('', 'Error occured, please try again.');
    });

  }

  fetchDepositFiat(currency = null) {
    this.searchObj['currency'] = currency ?? this.searchObj['currency'];
    this.tradeService.getFiatDeposit("", "", this.searchObj['currency'], false).subscribe((res) => {
      this.fiatdepositlist = res.deposit;
      this.bindFiatHistoryGrid(res.deposit);

    })
  }

  exportExcel(history: string) {
    if (history == 'crypto_deposit_list') {
      this.tradeService.getDeposit("", "", this.searchObj.currency, true).subscribe((result) => {
        var response = result.deposit;
        var exportarr = []
        for (var i = 0; i < response.length; i++) {
          exportarr.push({
            Txnid: response[i].txnid,
            txHash: response[i].txHash,
            Currency: response[i].currency,
            Amount: this.round(response[i].amount, 5),
            Fee: this.round(response[i].fee, 5),
            Address: response[i].address,
            "Date of Transaction (CET)": this.dateformat(response[i].createdAt),
            Status: response[i].status,
          })
        }
        let fileName = 'crypto_depositlist.xlsx';
        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportarr);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, fileName);
      })
    } else {
      this.tradeService.getFiatDeposit("", "", this.searchObj['currency'], true).subscribe((res) => {
        var fiatdepositlist = res.deposit;
        var exportarr = []
        for (var i = 0; i < fiatdepositlist.length; i++) {
          exportarr.push({
            "Blockoville txnID": fiatdepositlist[i].otctxnid,
            Currency: fiatdepositlist[i].currency,
            Amount: this.round(fiatdepositlist[i].amount, 2),
            "Trans Ref ID": fiatdepositlist[i].txnid,
            "Date of Transaction (CET)": this.dateformat(fiatdepositlist[i].createdAt),
            Status: fiatdepositlist[i].status,
          })
        }
        // this.fiatDepositExport = exportarr;
        let fileName = 'fiat_depositlist.xlsx';
        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportarr);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, fileName);
      })
    }
  }

  changeFiatAmount() {
    if (!(this.transObj.amount == '' || this.transObj.amount == null || Number(this.transObj.amount) < 0)) {
      var percentageFee = (this.transObj.amount * this.bankActive.fee_percentage / 100)

      if (percentageFee > this.bankActive.min_fee) {
        this.fiatCurrencyActive.receive_amount = this.transObj.amount - percentageFee;
        this.fiatCurrencyActive.fee_amount = percentageFee;
      } else {
        this.fiatCurrencyActive.receive_amount = this.transObj.amount - this.bankActive.min_fee;
        this.fiatCurrencyActive.fee_amount = this.bankActive.min_fee;
      }
    } else {
      this.fiatCurrencyActive.receive_amount = '';
      this.fiatCurrencyActive.fee_amount = '';
    }

  }

  addTransaction(addTrans: NgForm) {
    if (!this.bankActive._id) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'Palun valige pank')
      } else {
        this.notifyService.showError("", 'Please select the Bank')
      }
      return;
    } else {
      this.transObj['bank_account_id'] = this.bankActive._id;
    }

    if (this.transObj['depProof'] === 'no file choosen' || this.transObj['depProof'] === '') {
      return this.notifyService.showError('', 'Please attach Transaction Proof')
    }
    if (!this.transObj['clearjnID']) {
      return this.notifyService.showError('', 'Please Give valid Transaction Id')
    }
    if (Number(this.fiatCurrencyActive.receive_amount) <= 0) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'Saadud summa peab olema suurem kui 0')
      } else {
        this.notifyService.showError("", 'The received amount should be greater than 0')
      }
      return;
    } else {
      this.transObj['fee'] = this.fiatCurrencyActive.fee_amount;
    }

    if (this.transObj.amount == '' || this.transObj.amount == null) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'Ei ole kehtiv summa')
      } else {
        this.notifyService.showError("", 'Not a valid Amount')
      }
      return;
    } else if (Number(this.transObj.amount) < this.fiatCurrencyActive?.currency_config?.DepositMinimum) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", "Lubatud minimaalne kogus on " + this.fiatCurrencyActive?.currency_config?.DepositMinimum)
      } else {
        this.notifyService.showError("", 'Allowed minimum amount is ' + this.fiatCurrencyActive?.currency_config?.DepositMinimum)
      }
      return;
    } else if (Number(this.transObj.amount) > this.fiatCurrencyActive?.currency_config?.DepositMaximum) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", "Lubatud maksimaalne kogus on " + this.fiatCurrencyActive?.currency_config?.DepositMaximum)
      } else {
        this.notifyService.showError("", 'Allowed maximum amount is ' + this.fiatCurrencyActive?.currency_config?.DepositMaximum)
      }
      return;
    }

    this.userService.csrf().subscribe((response) => {
      this.transObj['_csrf'] = response._csrf;
      this.userService.addBalanceRequest(this.transObj).subscribe(data => {
        this.transObj['depProof'] = "no file choosen";;
        this.view_proof = "no file choosen";
        this.fetchDepositFiat();
        if (data.status == true) {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showSuccess("", data.messagees)
          } else {
            this.notifyService.showSuccess("", data.message)
          }
        } else {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", data.messagees)
          } else {
            this.notifyService.showError("", data.message)
          }
        }
        addTrans.resetForm();
        this.fiatCurrencyActive.receive_amount = '';
        this.fiatCurrencyActive.fee_amount = '';
      }, (err) => {
        console.log(err);
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", err.error.messagees)
        } else {
          this.notifyService.showError("", err.error.message)
        }
      })
    })
  }

  OnIDProofUploade(files, key): void {
    var path = files[0].type;
    var filezsize: any = '';
    var maxdocsize: any = '';
    var maxvidsize: any = '';
    this.fileToUpload = files.item(0);
    if (path == 'image/jpeg') {
      filezsize = files[0].size / 1000;
      maxdocsize = '200.00' //kb
    }
    if (path == 'video/mp4') {
      filezsize = files[0].size / 1e+6;
      maxvidsize = 3; //mb
    }
    if (filezsize > maxdocsize && path == 'image/jpeg') {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", "Pildi suuruse limiit on ületatud")
      } else {
        this.notifyService.showError("", "Image size limit exceeded");
      }

      return;
    }
    if (filezsize > maxvidsize && path == 'video/mp4') {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", "Video suuruse limiit on ületatud")
      } else {
        this.notifyService.showError("", "Video size limit exceeded");
      }


      return;
    }
    if (key == 'depProof' && path !== "image/jpeg") {
      if (path !== "application/pdf") {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", "Laadige üles ainult jpeg-vormingus või PDF-vormingus")
        } else {
          this.notifyService.showError("", "Only Upload jpeg Image Format Or PDF Format");
        }
        return;
      }
    }
    this.userService.csrf().subscribe((response) => {
      this.userService.uploadDocn(this.fileToUpload, key, response._csrf).subscribe(data => {
        var result = data.result.secure_url;
        this.transObj['depProof'] = result;
        var url = this.S3_BUCKET_URL;
        this.view_proof = this.transObj['depProof'].replace(url, "");
        this.view_proof = this.view_proof.toLowerCase();
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showSuccess("", data.messagees)
        } else {
          this.notifyService.showSuccess("", data.message);
        }

      }, err => {
        this.notifyService.showError("", err.error.message);
      })
    })
  }

  //crypto deposit tab table.
  onCyptoGridReady(params: GridReadyEvent) {
    this.cyptoDepositGridApi = params.api;
    this.cyptoDepositGridApi.sizeColumnsToFit();
    this.cyptoDepositGridApi.setDomLayout('autoHeight');
  }

  getCyptoRowNodeId(data) {
    return data['txnid']
  }

  bindCyptoGrid(data: any) {
    this.cyptoDepositGridApi.setRowData([]);
    if (data && data.length > 0) {
      for (let dt of data) {
        let selectedNode = this.cyptoDepositGridApi.getRowNode(dt['txnid']);
        if (selectedNode) {
          this.cyptoDepositGridApi.applyTransaction({ update: [dt] });
        } else {
          this.cyptoDepositGridApi.applyTransaction({ add: [dt] })
        }
      }
    }
  }

  setCurrencyFilter(value: any) {
    this.cyptoDepositGridApi.setQuickFilter(value);
  }

  filterZeroBalanceCurrency(event: MatCheckboxChange) {
    if (event.checked) {
      this.cryptoWallets = this.cryptoSearchCopy.filter(function (item) {
        return (item && Number(item.availableBalance) > 0) ? true : false;
      });
    } else {
      this.cryptoWallets = this.cryptoSearchCopy
    }
  }

  //crypto deposit history
  onCyptoHistoryGridReady(params: GridReadyEvent) {
    this.cyptoDepositHistoryGridApi = params.api;
    this.cyptoDepositHistoryGridApi.sizeColumnsToFit();
    this.cyptoDepositHistoryGridApi.setDomLayout('autoHeight');
  }

  getCyptoHistoryRowNodeId(data) {
    return data['txnid']
  }

  bindCyptoHistoryGrid(data: any) {
    this.cyptoDepositHistoryGridApi.setRowData([]);
    if (data && data.length > 0) {
      for (let dt of data) {
        let selectedNode = this.cyptoDepositHistoryGridApi.getRowNode(dt['txnid']);
        if (selectedNode) {
          this.cyptoDepositHistoryGridApi.applyTransaction({ update: [dt] });
        } else {
          this.cyptoDepositHistoryGridApi.applyTransaction({ add: [dt] })
        }
      }
    }
  }

  bindSingleCyptoHistory(dt: any) {
    let selectedNode = this.cyptoDepositHistoryGridApi.getRowNode(dt['txnid']);
    if (selectedNode) {
      this.cyptoDepositHistoryGridApi.applyTransaction({ update: [dt] });
    } else {
      this.cyptoDepositHistoryGridApi.applyTransaction({ add: [dt] })
    }
  }

  applyCyptoDepositHistoryFilter(event: any) {
    this.cyptoDepositHistoryGridApi.setQuickFilter(event.target.value)
  }

  //fiat currency
  onFiatHistoryGridReady(params: GridReadyEvent) {
    this.fiatDepositHistoryGridApi = params.api;
    this.fiatDepositHistoryGridApi.sizeColumnsToFit();
    this.fiatDepositHistoryGridApi.setDomLayout('autoHeight');
  }

  getFiatHistoryRowNodeId(data) {
    return data['txnid']
  }

  bindFiatHistoryGrid(data: any) {
    this.fiatDepositHistoryGridApi.setRowData([]);
    if (data && data.length > 0) {
      for (let dt of data) {
        let selectedNode = this.fiatDepositHistoryGridApi.getRowNode(dt['txnid']);
        if (selectedNode) {
          this.fiatDepositHistoryGridApi.applyTransaction({ update: [dt] });
        } else {
          this.fiatDepositHistoryGridApi.applyTransaction({ add: [dt] })
        }
      }
    }
  }

  bindSingleFiatHistory(dt: any) {
    let selectedNode = this.fiatDepositHistoryGridApi.getRowNode(dt['txnid']);
    if (selectedNode) {
      this.fiatDepositHistoryGridApi.applyTransaction({ update: [dt] });
    } else {
      this.fiatDepositHistoryGridApi.applyTransaction({ add: [dt] })
    }
  }

  applyFiatDepositHistoryFilter(event: any) {
    this.fiatDepositHistoryGridApi.setQuickFilter(event.target.value)
  }

  getAllBlockchainConfig() {
    this.userService.getAllBlockchainConfig().subscribe((result) => {
      if (result) {
        this.blockchainConfig = result;
      }
    }, (err) => {
      console.log(err);
    });
  }

  walletConnect() {
    window.open(`${environment.walletConnectURL}?to=${this.cryptoCurrencyActive?.activeAddress.address}&currency=${this.cryptoCurrencyActive.currency}&chain=${environment.wcSupportedChains[this.blockchain.Blockchain]}&cancel_url=${encodeURI(environment.home + "deposit")}`)
  }

  checkWalletConnectAvailability() {
    console.log(this.blockchain, "chain")
    if (environment.wcSupportedChains[this.blockchain.Blockchain] && this.blockchain.CurrencySymbol != 'USDT' && this.blockchain.CurrencySymbol != 'SHIB' && this.blockchain.CurrencySymbol != 'USDC') {
      this.walletConnectEnabled = true;
    } else {
      this.walletConnectEnabled = false;
    }
  }

  manageBankSave() {
    if (this.formManageBank.firstName == '' || this.formManageBank.firstName == null) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'Eesnimi on kohustuslik')
      } else {
        this.notifyService.showError("", 'First Name is required')
      }
      return;
    }

    if (this.formManageBank.lastName == '' || this.formManageBank.lastName == null) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'Perekonnanimi on kohustuslik')
      } else {
        this.notifyService.showError("", 'Last Name is required')
      }
      return;
    } 

    if (this.formManageBank.mobileNumber == '' || this.formManageBank.mobileNumber == null) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'Mobiilinumber on nõutav')
      } else {
        this.notifyService.showError("", 'Mobile Number is required')
      }
      return;
    } 

    if (this.formManageBank.panNo == '' || this.formManageBank.panNo == null) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'PAN-number on nõutav')
      } else {
        this.notifyService.showError("", 'PAN Number is required')
      }
      return;
    } 

    if (this.formManageBank.ifscCode == '' || this.formManageBank.ifscCode == null) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'IFSC kood on nõutav')
      } else {
        this.notifyService.showError("", 'IFSC Code is required')
      }
      return;
    } 

    if (this.formManageBank.remitterAccountNumber == '' || this.formManageBank.remitterAccountNumber == null) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'Maksja kontonumber on nõutav')
      } else {
        this.notifyService.showError("", 'Remitter Account Number is required')
      }
      return;
    } 

    this.userService.csrf().subscribe((response) => {
      this.formManageBank['_csrf'] = response._csrf;
      this.userService.createManageBank(this.formManageBank).subscribe(data => {
        if (data.status == true) {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showSuccess("", data.messagees)
          } else {
            this.notifyService.showSuccess("", data.message)
          }
          this.getBankAccounts();
          this.formManageBank = {
            "firstName": "",
            "lastName": "",
            "middleName": "",
            "mobileNumber": "",
            "panNo": "",
            "ifscCode": "",
            "remitterAccountNumber": ""
          };
        } else {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", data.messagees)
          } else {
            this.notifyService.showError("", data.message)
          }
        }
      }, (err) => {
        console.log(err);
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", err.error.messagees)
        } else {
          this.notifyService.showError("", err.error.message)
        }
      })
    })
    
  }

  getBankAccounts() {
    this.userService.getBankAccounts().subscribe((result) => {
      if (result) {
        this.bankAccounts = result;
      }
    }, (err) => {
      console.log(err);
    });
  }
}
